<template>
    <div class="home">

        <div style="
        z-index:2;
            height: 60px;
            line-height: 60px;
            right: 42px;
    position: fixed;
    text-align: right;
    font-size: 24px;"><i @click="chacha" class="el-icon-close"></i></div>
      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="180px">
          <div v-for="(item,index) in form.label" class="flex" :key="index">
          <el-form-item style="font-weight: bold;"  label="一级类型">
              <div  class="flex">
            <el-select @change='ftype(index,$event)' v-model="item.f_type" placeholder="请选择">
                  <el-option
                    v-for="ite in item.options"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
            </el-select>
            <el-select v-if="item.options2.length != 0" v-model="item.c_type" placeholder="请选择">
                  <el-option
                    v-for="ite in item.options2"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
            </el-select>
            </div>
          </el-form-item>
          <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.query" style="width:300px" placeholder="操作人、标签编号、标签名称"></el-input>
          <el-button style="margin-left:30px;" type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
          </div>
      </el-form>
    </div>
      <div class="pt20"></div>
        <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%" >
       <el-table-column  width="1" align="center"/>
          <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50">
        </el-table-column>
        <el-table-column
          prop="name"
          label="标签名">
        </el-table-column>
        <el-table-column
          prop="f_type.name" 
          label="一级分类">
        </el-table-column>
        <el-table-column
          prop="c_type.name" 
          label="二级分类">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button @click="goedit(scope.row)" size="mini" type="info" plain>使用</el-button>
          </template>
        </el-table-column>
      </el-table>
      
<el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      form: {
          label:[{
            query:'',
            f_type:-1,
            c_type:-1,
            options:[],
            options2:[],
          }]
        },
        formInline:{
          sdate:'',
          edate:'',
          c_type:-1,
          f_type:-1,
          query:'',
          page:1,
        }
    }
  },
  methods: {
     onSubmit(CHA) {
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
      let _this = this;
       _this.loading = true;
      let postdata = this.form.label[0];
      _this.formInline.c_type = postdata.c_type?postdata.c_type:-1;
      _this.formInline.f_type = postdata.f_type?postdata.f_type:-1;
      
      axios.get(config.label,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goedit(obj){
          let arr = [];
          arr.push({
              id:obj.id,
              name:obj.name,
          })
          if(sessionStorage.getItem('artedit') != ''){
        let select = JSON.parse(sessionStorage.getItem('artedit'));
            for(let i in select){
                arr.push({
                    id:select[i].id,
                    name:select[i].name,
                });
            }
        }
        sessionStorage.setItem("artedit", JSON.stringify(arr));
        this.$router.push('/article/editarticlelabel');
      },
      goNav(url){
        this.$router.push(url)
      },// 增加
    add() {
          let _this = this;
        this.form.label.push({
            name:'',
            f_type:-1,
            c_type:-1,
            options:[],
            options2:[],
          });
          let i = this.form.label.length -1;
        axios.get(config.selectlabelCategory,{params:{id:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.label[i].options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 删除
    deleteData(index) {
      if (this.form.label.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.label.splice(index, 1);
      }
    },
    ftype(obj,e){
      let _this = this;
      if(e != -1){
      axios.get(config.selectlabelCategory,{params:{id:e}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.label[obj].options2 = response.data.data;
              _this.form.label[obj].c_type = -1;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      }else{
        _this.form.label[obj].options2 = [];
        _this.form.label[obj].c_type = 0;
      }
    },
     chacha() {
     this.$router.push('/article/editarticlelabel');
    },
    init(){
        let _this = this;
        axios.get(config.selectlabelCategory,{params:{id:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.label[0].options = response.data.data
              _this.setpage(response.data.data)
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });

        axios.get(config.label,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.form{
    display: flex;
}
.flex{
    display: flex;
}
.w{
    width:300px;
}
.ww{
    width: 70px;
}
</style>
